<template>
  <v-row>
    <v-col cols="12" class="text-center">
      <v-row align="center" justify="center" style="height: 300px">
        <v-card class="ma-3 pa-6" outlined tile>
          <v-card-title> {{ title }} </v-card-title>
          <v-card-subtitle> {{ subtitle }} </v-card-subtitle>
          <v-card-actions class="justify-center">
            <v-btn outlined :disabled="!logged_out" to="/login">
              Retour Accueil
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: function () {
    return {
      title: "Déconnexion en cours...",
      subtitle: "",
      logged_out: false,
      timeout: undefined,
    };
  },
  methods: {
    logout() {
      if (this.$route.query.logged_out) {
        this.logged_out = true;
        this.title = "Déconnecté avec succès !";
        this.subtitle =
          "Vous allez être redirigé automatiquement dans 3 secondes...";
        this.timeout = setTimeout(() => this.$router.push("/login"), 3000);
      } else {
        window.location = process.env.VUE_APP_API_URL + "/logout";
      }
    },
  },
  created() {
    this.logout();
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>

<style>
</style>
